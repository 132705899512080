import React from "react";
import { withAuthentication } from "../../hoc/withAuthentication";
import Main from "../../components/main";
import TextArea from "../../components/textArea";
import ContentArea from "../../components/contentArea";
import Consts from "../../config/consts";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED],
        redirectPage: "/",
    },
    class Privacy extends React.Component {
        // export default class Home extends React.Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {}

        render() {
            return (
                <Main title={"BMinted"} auth={this.props.auth} prices={this.props.prices} providers={this.props.providers} currentChain={this.props.currentChain} chains={this.props.chains}>
                    <ContentArea slim={true} header={"Privacy Policy"} extraTopPadding={true}></ContentArea>
                </Main>
            );
        }
    }
);
